import $ from 'jquery';
/* eslint-disable func-names */
$('html').on('click', 'a', function () {
  const link = this;
  let leavingSite = true;
  // Ignore javascript and mailto links.
  /* eslint-disable no-script-url */
  const exemptProtocols = ['mailto:', 'javascript:'];
  if ($.inArray(link.protocol, exemptProtocols) !== -1) {
    leavingSite = false;
  } else if (!link.hostname) {
    leavingSite = false;
  } else if (link.hostname) {
    // Check the simplest, quickest case of the links hostname being the
    // same as the current page's hostname.
    if (link.hostname === window.location.hostname) {
      leavingSite = false;
    } else {
      // Determine the base hostname of the link (eg, just "energy.gov" for
      // "www.afdc.energy.gov").
      const hostnameParts = link.hostname.split('.');
      let baseHostname = `${hostnameParts[hostnameParts.length - 2]}.${
        hostnameParts[hostnameParts.length - 1]
      }`;
      baseHostname = baseHostname.toLowerCase();
      // See if the base hostname is in a whitelist of "internal" sites or if it's a .gov.
      const internalSites = ['youtube.com', 'govdelivery.com'];
      if (
        $.inArray(baseHostname, internalSites) !== -1
        || baseHostname.endsWith('.gov')
      ) {
        leavingSite = false;
      }
    }
  }

  if (leavingSite) {
    // Allow the warning to be disabled if the
    // "disable-external-link-warning" data attribute element is present.
    const $link = $(link);
    if ($link.data('disable-external-link-warning') === true) {
      leavingSite = false;
    }
  }

  if (leavingSite) {
    /* eslint-disable no-alert */
    return window.confirm(
      /* eslint-disable quotes, comma-dangle */
      'This link will take you to an external website. We are not responsible for their content.',
    );
  }
  return true;
});
