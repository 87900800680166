import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFileImage } from '@fortawesome/free-solid-svg-icons/faFileImage';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons/faFilePowerpoint';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons/faFileArchive';
import { config, icon } from '@fortawesome/fontawesome-svg-core';

// Manually import CSS into stylesheet file for better CSP compatibility.
config.autoAddCss = false;

const fileTypes = {
  doc: faFileWord,
  docx: faFileWord,
  pdf: faFilePdf,
  ppt: faFilePowerpoint,
  pptx: faFilePowerpoint,
  potx: faFilePowerpoint,
  xls: faFileExcel,
  xlsx: faFileExcel,
  xlsm: faFileExcel,
  jpg: faFileImage,
  png: faFileImage,
  gif: faFileImage,
  tif: faFileImage,
  eps: faFileImage,
  zip: faFileArchive,
};

const altText = {
  doc: 'Microsoft Word',
  docx: 'Microsoft Word',
  pdf: 'PDF',
  ppt: 'Microsoft PowerPoint',
  pptx: 'Microsoft PowerPoint',
  potx: 'Microsoft PowerPoint',
  xls: 'Microsoft Excel',
  xlsx: 'Microsoft Excel',
  xlsm: 'Microsoft Excel',
  jpg: 'Image',
  png: 'Image',
  gif: 'Image',
  tif: 'Image',
  eps: 'Image',
  zip: 'ZIP Archive',
};

const cache = {};
function buildIcon(iconData, alt) {
  // Determine a unique cache ID for either FontAwesome icons, or the raw image
  // icons.
  const cacheId = (iconData.icon) ? `${iconData.prefix}-${iconData.iconName}` : iconData;
  if (!cache[cacheId]) {
    // Handle imported FontAwesome SVG icons.
    if (iconData.icon) {
      const faIcon = icon(iconData, { classes: ['template-auto-icon'] });
      // eslint-disable-next-line prefer-destructuring
      cache[cacheId] = faIcon.node[0];

    // Handle inline image data.
    } else {
      const image = new Image();
      image.src = iconData;
      image.alt = alt;
      image.className = 'template-auto-icon icon-file';
      cache[cacheId] = image;
    }
  }

  return cache[cacheId].cloneNode(true);
}

function addFileLinkIcon(link) {
  if (link.classList.contains('skip-file-icon') || link.classList.contains('skip-template-auto-icon')) {
    return;
  }

  // Don't add icons to links that contain images.
  if (link.querySelector('img')) {
    return;
  }

  switch (link.protocol) {
    case 'mailto:':
      link.insertBefore(buildIcon(faEnvelope), link.firstChild);
      break;
    case 'tel:':
      link.insertBefore(buildIcon(faPhone), link.firstChild);
      break;
    default: {
      // Calendar icons use FontAwesome and prepend the icon. Other file types
      // use images and append the icon.
      const extension = link.pathname.split('.').pop().toLowerCase();
      if (extension === 'ics') {
        link.insertBefore(buildIcon(faCalendarAlt), link.firstChild);
      } else if (fileTypes[extension]) {
        link.appendChild(buildIcon(fileTypes[extension], altText[extension]));
      }

      break;
    }
  }
}

function addHeaderLinkIcon(header) {
  if (header.classList.contains('skip-template-auto-icon')) {
    return;
  }

  if (header.classList.contains('clear-search')) {
    header.insertBefore(buildIcon(faCircleXmark), header.firstChild);
  } else {
    header.appendChild(buildIcon(faChevronCircleRight));
  }
}

function appendNodeMatches(matches, selector, node) {
  if (node instanceof Element) {
    if (node.matches(selector)) {
      matches.push(node);
    }

    // eslint-disable-next-line prefer-spread
    matches.push.apply(matches, node.querySelectorAll(selector));
  }
}

function appendTreeMatches(matches, selector, nodes) {
  for (let i = 0, len = nodes.length; i < len; i += 1) {
    appendNodeMatches(matches, selector, nodes[i]);
  }
}

// Use the MutationObserver API to insert icons into certain selectors (eg,
// file links or linked headers). By using the MutationObserver (rather than
// just executing this logic on document ready), this ensures that dynamic
// content on the page will be updated appropriately for JavaScript-heavy apps
// (eg, if a file download link is generated by JavaScript and injected onto
// the page after load).
//
// Ideally, we could handle all this via CSS pseudo selectors, but since we're
// trying to use FontAwesome SVG icons, this gets tricky to handle with just
// pseudo-CSS selectors and inline SVGs. FontAwesome does support
// pseudo-selectors
// (https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements),
// but when using the SVG icons, this can become very non-performant
// (https://fontawesome.com/how-to-use/with-the-api/setup/configuration#searchpseudoelements).
// This approach here uses the same basic strategy as FontAwesome's normal
// JavaScript/SVG library, which also utilizes the MutationObserver
// (https://fontawesome.com/how-to-use/with-the-api/methods/dom-watch), so it
// should be performant (since the pseudo-selectors don't have to be parsed and
// observed).
function observe(selector, observerOptions, callback) {
  // Keep track of which nodes have already had the callback called, so we
  // don't execute the callback multiple times per node.
  const nodeCallbackCalled = [];
  // eslint-disable-next-line func-names
  const matchesCallback = function (matches) {
    for (let i = 0, len = matches.length; i < len; i += 1) {
      const match = matches[i];
      if (nodeCallbackCalled.indexOf(match) === -1) {
        callback(matches[i]);
        nodeCallbackCalled.push(match);
      }
    }
  };

  // Check for any elements already on the page.
  matchesCallback(document.querySelectorAll(selector));

  // Setup an observer to track any future elements matching our selector that
  // get added to the page.
  const observer = new MutationObserver((mutations) => {
    const matches = [];
    for (let i = 0, len = mutations.length; i < len; i += 1) {
      const mutation = mutations[i];
      if (mutation.type === 'childList') {
        appendTreeMatches(matches, selector, mutation.addedNodes);
      } else if (mutation.type === 'attributes') {
        appendNodeMatches(matches, selector, mutation.target);
      }
    }

    matchesCallback(matches);
  });
  observer.observe(document, observerOptions);
}

observe('a', {
  attributeFilter: ['href'],
  attributes: true,
  childList: true,
  subtree: true,
}, addFileLinkIcon);

observe('h2 a, h3 a, h4 a, h5 a, a div h2, a div h3, a div h4, a div h5, a.clear-search', {
  childList: true,
  subtree: true,
}, addHeaderLinkIcon);
