import { faBicycle } from '@fortawesome/free-solid-svg-icons/faBicycle';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faBusSimple } from '@fortawesome/free-solid-svg-icons/faBusSimple';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons/faChargingStation';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassDollar';
import { faMapMarked } from '@fortawesome/free-solid-svg-icons/faMapMarked';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faSmog } from '@fortawesome/free-solid-svg-icons/faSmog';
import { faTaxi } from '@fortawesome/free-solid-svg-icons/faTaxi';
import { faTools } from '@fortawesome/free-solid-svg-icons/faTools';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faSquareRss } from '@fortawesome/free-solid-svg-icons/faSquareRss';

import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

// Manually import CSS into stylesheet file for better CSP compatibility.
config.autoAddCss = false;

library.add(
  faBicycle,
  faBullhorn,
  faBus,
  faBusSimple,
  faChargingStation,
  faClipboardList,
  faDollarSign,
  faEnvelope,
  faGoogle,
  faLink,
  faMagnifyingGlassDollar,
  faMapMarked,
  faMapMarkerAlt,
  faPlug,
  faNetworkWired,
  faQuestionCircle,
  faRoad,
  faSmog,
  faTaxi,
  faTools,
  faMinusSquare,
  faPlusSquare,
  faUserFriends,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSquareRss,
);

dom.watch();
